@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700|Lora|Playfair+Display:700i,900");
@tailwind base;
@tailwind components;

@layer components {
  @keyframes slideInFromLeft {
    0% {
      width: 0%;
    }
    70% {
      width: 120%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes fadeInHighlight {
    0% {
      transform: -100%;
    }
    100% {
      border-bottom-width: 2px;
    }
  }

  .typo-marker {
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }

  .typo-marker:after {
    background-color: #ff5959;
    opacity: 65%;
    content: " ";
    height: 40%;
    position: absolute;
    overflow: hidden;
    left: 0;
    margin-left: 0;
    top: 55%;
    z-index: -1;
    width: 100%;
    animation: 0.3s ease-in-out slideInFromLeft;
  }

  .seo-marker {
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }

  .seo-marker:after {
    background-color: #59afff;
    opacity: 65%;
    content: " ";
    height: 20%;
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 0%;
    z-index: -1;
    margin-left: 2.5%;
    margin-right: 2.5%;
    width: 95%;
    animation: 0.3s ease-in-out slideInFromLeft;
  }

  // Insert Marker
  .insert-marker {
    background-color: #caf1ed;
  }

  .autocomplete-marker {
    @apply text-neutral-800/30;
  }

  .Typewriter {
    word-break: break-all;
  }

  pre > div {
    background: none !important;
  }

  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .animate-blink {
    animation: blink 800ms infinite;
  }
}

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";
@import "additional-styles/quilljs.scss";
@import "react-toastify/dist/ReactToastify.css";

@import "custom-styles/quilljs-theme.scss";

@tailwind utilities;
