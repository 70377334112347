// General
.ql-editor {
  line-height: 2;
  font-family: "Noto Serif TC", "Noto Serif SC", "Noto Serif JP", sans-serif;
  color: rgb(36, 36, 36);
  letter-spacing: 0.36px;
  text-align: justify;
  height: 100%;
  overflow: visible;
  @apply text-[#242424] dark:text-white;
}

// Placeholder
.ql-editor.ql-blank {
  font-size: 1.25em;
}

// header use font weight 700
.ql-editor h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.ql-editor p {
  font-family: "Noto Serif TC", "Noto Serif SC", "Noto Serif JP", sans-serif;
  line-height: 2;
  @apply text-[#242424] dark:text-white;
}

.ql-editor blockquote {
  font-family: "Playfair Display", "Noto Serif TC", "Noto Serif SC",
    "Noto Serif JP", sans-serif;
  font-size: 30px;
  font-style: italic;
  letter-spacing: -0.36px;
  line-height: 44.4px;
  overflow-wrap: break-word;
  margin: 55px 0 33px 0;
  /* text-align: center; */
  @apply text-neutral-600 dark:text-white;
  padding: 0 0 0 50px;
  border-left: none !important;
  text-align: center;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

// Add quote before and after blockquote
.ql-editor blockquote:before {
  content: "“";
  font-size: 2em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
  @apply text-neutral-600 dark:text-white;
}

.ql-editor blockquote:after {
  content: "”";
  font-size: 2em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -0.4em;
  @apply text-neutral-600 dark:text-white;
}

// List
.ql-editor ul {
  padding-left: 0;
}

// Remove ul li::before
.ql-editor ul li::before {
  content: "*";
}

.ql-bubble .ql-editor pre.ql-syntax {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: rgb(242, 242, 242);
  border-radius: 0px;
  padding: 1.5rem;
  font-family: source-code-pro, Menlo, Monaco, "Courier New", Courier, monospace;
  overflow-x: auto;
  color: rgb(36, 36, 36);
  font-size: 0.95rem;
}

.ql-clipboard {
  position: fixed;
}
